var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "국내사업",
      "tabActive": "사회복지 전략기획사업",
      "bg": "/images/sub/visual/sv2.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('page-title', {
    attrs: {
      "tit": "사회복지 전략기획사업",
      "sc": "사각지대에 놓인 지역 주민들의 삶의 질 향상을 위해 지역사회 내 사회복지 시설의 신규 유치 및 운영을 지원하고, 최근 사회복지 동향에 대한 전문 정보의 공유를 통해사회복지의 현대화·전문화에 기여함으로써 사회복지 활동 영역의 기반확대, 사회적 위상 제고"
    }
  }), _c('v-row', {
    staticClass: "word-keep-all text-center border",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "border-bottom border-md-bottom-0 border-md-end",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "pa-20 px-md-24 py-md-34 py-lg-60 d-flex flex-column align-center"
  }, [_c('v-img', {
    staticClass: "mb-16 mb-lg-24",
    attrs: {
      "src": "/images/sub/business/welfare/welfare-icon.svg",
      "max-width": "60",
      "aspect-ratio": 1 / 1
    }
  }), _c('h6', {
    staticClass: "page-text page-text--lg grey-1--text mb-4"
  }, [_vm._v(" 중장기 발전전략사업 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 사랑과 나눔, 협력의 가치 구현으로 모두가 행복한 세상을 만들어가는 미션을 기본으로 누구나 소외됨 없는 따뜻하고 행복할 수 있는 새로운 복지 전략사업을 추진. 또한 재단의 복지 운영 방향과 향후 전략에 대하여 사회 각 단체들과의 공감대 형성과 사회의 기대에 부응하는 대표 사회복지법인으로써 선도적 역할을 제고 ")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "pa-20 px-md-24 py-md-34 py-lg-60 d-flex flex-column align-center"
  }, [_c('v-img', {
    staticClass: "mb-16 mb-lg-24",
    attrs: {
      "src": "/images/sub/business/welfare/welfare-icon2.svg",
      "max-width": "60",
      "aspect-ratio": 1 / 1
    }
  }), _c('h6', {
    staticClass: "page-text page-text--lg grey-1--text mb-4"
  }, [_vm._v(" 비용 절감 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 시대적 요구에 부응하는 전문적 복지사업 수행을 위해 사회복지가치와 실행력을 겸비한 전문가들이 함께 신규 복지 프로그램 개발을 모색하여 사회문제 해결을 위한 새로운 사회복지영역 개척과 새로운 복지모형 개발 및 보급에 주력 ")])], 1)])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }